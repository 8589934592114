<template>
    <div>
        <vue2MeetingComponent>
            <template>
                <PageFilter 
                    model="meetings.PlannedMeetingModel"
                    key="page_list_meetings.PlannedMeetingModel"
                    filterButtonSize="large"
                    page_name="page_list_meetings.PlannedMeetingModel" />
            </template>
        </vue2MeetingComponent>
        <LongPressHelp />
    </div>
</template>

<script>
import PageFilter from '@/components/PageFilter'
import vue2MeetingComponent from '@apps/vue2MeetingComponent'
import pageMeta from '@/mixins/pageMeta'
import LongPressHelp from '@/components/LongPressHelp.vue'
export default {
    mixins: [pageMeta],
    components: {
        vue2MeetingComponent,
        PageFilter,
        LongPressHelp
    }
}
</script>
